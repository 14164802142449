import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { FooterLink } from "../components/v2/Footer";
import HeaderTypography from "../components/v2/Typography/HeaderTypography";
import MuiLink from "@material-ui/core/Link";
import Box from "@material-ui/core/Box";
import SEO from "../components/SEO";

const pepperCloud = [
	{
		title: "About Us",
		path: "/about",
	},
	{
		title: "Partner with Us",
		path: "/become-crm-partner",
	},
	{
		title: "Careers",
		path: "/careers",
	},
	{
		title: "Testimonials",
		path: "/crm-testimonials",
	},
	{
		title: "Case Studies",
		path: "/crm-case-studies",
	},
	{
		title: "Privacy Policy",
		path: "/privacy",
	},
	{
		title: "Terms of Service",
		path: "/terms-of-service",
	},
	{
		title: "Pricing Plans",
		path: "/crm-platfrom-pricing",
	},
	{
		title: "Avail Grant",
		path: "/psg-grant",
	},
];

const Resource = [
	{
		title: "Why Do You Need a CRM?",
		path: "https://blog.peppercloud.com/complete-guide-to-crm-software/",
	},
	{
		title: "Product Updates",
		path: "https://blog.peppercloud.com/tag/product-update/",
	},
	{
		title: "Singapore Grant",
		path: "https://blog.peppercloud.com/a-guide-to-singapore-government-grants/",
	},
	{
		title: "EDG Grant",
		path: "https://blog.peppercloud.com/an-overview-of-enterprise-development-grant-edg/",
	},
	{
		title: "PSG Grant",
		path: "https://blog.peppercloud.com/ultimate-guide-to-productivity-solutions-grant/",
	},
	{
		title: "Sales Book Synopsis",
		path: "https://blog.peppercloud.com/tag/sales-book-synopsis/",
	},

	{
		title: "FAQs",
		path: "/crm-faq",
	},
];

const FeatureList = [
	{
		title: "WhatsApp CRM",
		description: "Integrate with WhatsApp and close deals right from your CRM.",
		path: "/whatsapp-crm",
	},
	{
		title: "Channels",
		description: "Converse with customers from a single platform.",
		path: "/multichannel-integration-for-sales-marketing",
	},
	{
		title: "Sales Pipeline",
		description: "Organise your Leads and Opportunities with sales pipelines.",
		path: "/sales-pipeline-management-tool",
	},
	{
		title: "Security",
		description: "Safeguard your customers’ data with multi-layered security.",
		path: "/crm-data-security-protection",
	},
	{
		title: "Dashboard",
		description: "Track performance and sales visibility through visual dashboards.",
		path: "/sales-dashboard",
	},
	{
		title: "Integrations",
		description: "Communicate efficiently with email, calendar, & other integrations.",
		path: "/integration",
	},
	{
		title: "Web Forms",
		description: "Capture visitors into leads with customisable web forms.",
		path: "/lead-capture-generation-webform",
	},
	{
		title: "Inbox",
		description: "Access your emails and contacts from your CRM inbox.",
		path: "/crm-email-inbox-integration",
	},
	{
		title: "Xero",
		description: "Generate, send, and track the quotations with CRM Xero integration",
		path: "/xero",
	},
	{
		title: "Zoom",
		description:
			"Create face-to-face connections & close deals faster with video conferencing using Pepper Cloud CRM and Zoom Meetings Intergation.",
		path: "/zoom",
	},
];

function Sitemap() {
	return (
	<React.Fragment>
		<SEO
				canonical="/sitemap"
				description="Not sure how to navigate the Pepper Cloud CRM website? Find products and popular pages faster by using our sitemap."
				keywords="Sitemap"
				pathname="/sitemap"
				title="Site map | Pepper Cloud CRM"
			/>
		<Container>
			<HeaderTypography component="h1" fontSize={56} fontWeight={600} mt={1} my={1.5} textAlign="center" variant="h2">
				Site map
			</HeaderTypography>
			<Grid container spacing={8}>
				<Grid item md={5} xs={12}>
					<Box>
						<HeaderTypography component="h2" fontSize={18} fontWeight={600} mt={1} my={1.5} variant="h5">
							Pepper Cloud
						</HeaderTypography>
						{pepperCloud.map((each) => (
							<FooterLink {...each} color="#2E3F4F" key={each.title} />
						))}
					</Box>
				</Grid>
				<Grid item md={5} xs={12}>
					<Box px={{ md: 4 }}>
						<HeaderTypography component="h2" fontSize={18} fontWeight={600} mt={1} my={1.5} variant="h5">
							Features
						</HeaderTypography>
						{FeatureList.map((each) => (
							<FooterLink {...each} alt={each.description} color="#2E3F4F" key={each.title} />
						))}
					</Box>
				</Grid>
				<Grid item md={2} xs={12}>
					<Box>
						<HeaderTypography component="h2" fontSize={18} fontWeight={600} mt={1} my={1.5} variant="h5">
							Resource
						</HeaderTypography>
						{Resource.map((each) => (
							<FooterLink {...each} color="#2E3F4F" key={each.title} />
						))}
						<MuiLink download="Brochure.pdf" href={"/brochure.pdf"}>
							<FooterLink color="#2E3F4F" title="Download Brochure" />
						</MuiLink>
					</Box>
				</Grid>
			</Grid>
		</Container>
		</React.Fragment>
	);
}

export default Sitemap;
